import Vue from "vue";
import { submit } from "@/api/base";
import html2canvas from "html2canvas";

export async function screenshotToBase64(element) {
  let dataUrl = "";
  // 使用html2canvas将DOM元素转换为canvas对象
  await html2canvas(element)
    .then((canvas) => {
      // canvas对象生成成功，可以通过canvas.toDataURL()获取Base64编码的图像数据
      dataUrl = canvas.toDataURL("image/png", 1);
    })
    .catch((error) => {
      // canvas对象生成失败
      console.error(error);
    });
  return dataUrl;
}
export function fileToBase64(file) {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function () {
      imgResult = reader.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(imgResult);
    };
  });
}

export function urlToBase64(url) {
  return new Promise(function (resolve, reject) {
    var canvas = document.createElement("canvas"); //创建canvas DOM元素
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      canvas.height = img.height; //指定画板的高度,自定义
      canvas.width = img.width; //指定画板的宽度，自定义
      ctx.drawImage(img, 0, 0); //参数可自定义
      var type = "";
      if (
        url.toLowerCase().indexOf(".jpg") > 0 ||
        url.toLowerCase().indexOf(".jpeg") > 0
      ) {
        type = "jpeg";
      } else if (url.toLowerCase().indexOf(".png")) {
        type = "gif";
      } else {
        type = "png";
      }
      var dataUrl = canvas.toDataURL("image/" + type);
      canvas = null;
      resolve(dataUrl);
    };
    img.onerror = function (error) {
      reject(error);
    };
  });
}

export async function copyImage(dataUrl) {
  let data = await fetch(dataUrl);
  let blob = await data.blob();
  navigator.clipboard.write([
    // eslint-disable-next-line no-undef
    new ClipboardItem({
      [blob.type]: blob,
    }),
  ]);
  Vue.prototype.$message({
    type: "success",
    message: "复制成功，已复制到粘贴板",
  });
}

export function botSend(type, content, sendParam) {
  if (!sendParam.botType) {
    sendParam.botType = 0;
  }
  switch (sendParam.botType) {
    case 0:
    case 2:
      return qqSend(type, content, sendParam);
    default:
      Vue.prototype.$message({ type: 'error', message: '暂不支持' });
      return false;
  }
}

function qqSend(type, obj, sendParam) {
  let content = "";
  switch (type) {
    case "face":
      content = `[CQ:face,id=${obj}]`;
      break;
    case "img":
      content = `[CQ:image,file=base64://${obj.substring(
        obj.indexOf("base64,") + 7
      )}]`;
      break;
    default:
      content = obj;
      break;
  }
  sendParam.content = content;
  return sendRequest(sendParam);
}

async function sendRequest(sendParam) {
  if (
    !sendParam ||
    !sendParam.content ||
    !sendParam.botUid ||
    !sendParam.senderUid
  )
    return false;

  let res = await submit("/api/stallBot/send", sendParam);
  if (res.data) {
    Vue.prototype.$message({ type: "error", message: res.data });
    return false;
  }
  return true;
}
